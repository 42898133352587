
  
  .App .reactEasyCrop_Container {
    touch-action: auto;
  }
  
  .App .removeTouchAction {
    touch-action: none;
  }
  
  .crop-container {
    position: relative;
    width:85%;
  }
  
  .controls {
    width: 15%;
    /* height: 40px; */
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction:column;
  }

  .Main {
    display: flex;
    flex-direction:row
  }

  @media (max-width: 600px) {
    .Main {
      display: flex;
      flex-direction:column
    }
    .controls{
      /* margin-top: 15px; */
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    }
    .crop-container{
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .bottonGirar{
      margin-bottom: 0px !important;
      margin-right: 9px !important;
    }

  }
  
  .slider {
    padding: 22px 0px;
  }
  
  .zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2px;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    background: #3f51b5;
    width: 100%;
  }
  
  .zoom-range::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  
  .zoom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: pointer;
  }
  
  .controls:hover input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
  }
  
  .controls:hover input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  }
  
  .zoom-hint,
  .touch-hint {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    pointer-events: none;
    text-align: center;
  }
  
  .App .reactEasyCrop_Container {
    touch-action: auto;
    border-radius: 10px !important;
}


.reactEasyCrop_CropArea {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  box-shadow: 0 0 0 9999em;
  color: rgba(237, 237, 237, 0.5) !important;
  overflow: hidden;
  border-radius: 10px;
}