@import "~@fortawesome/fontawesome-free/css/all.css";

:root {
    --color-rosa: #D14081 !important;
    --color-amarilo: #ffec00 !important;
    --color-gris: #c8cacb !important;
    --color-verde:#374957 !important;
  }





  @media (max-width: 992px) {
    .mapa, .mapacovertura{
      width: 100% !important;
      height: 900px !important;
    }
  }
  @media (max-width: 942px) {
    .mapa, .mapacovertura{
      width: 100% !important;
      height: 850px !important;
    }
  }
  @media (max-width: 892px) {
    .mapa, .mapacovertura{
      width: 100% !important;
      height: 800px !important;
    }
  }

  @media (max-width: 842px) {
    .mapa, .mapacovertura{
      width: 100% !important;
      height: 750px !important;
    }
  }

  @media (max-width: 792px) {
    .mapa, .mapacovertura{
      width: 100% !important;
      height: 700px !important;
    }
  }

  @media (max-width: 742px) {
    .mapa, .mapacovertura{
      width: 100% !important;
      height: 650px !important;
    }
  }
  @media (max-width: 692px) {
    .mapa, .mapacovertura{
      width: 100% !important;
      height: 600px !important;
    }
  }
  @media (max-width: 642px) {
    .mapa, .mapacovertura{
      width: 100% !important;
      height: 550px !important;
    }
  }
  @media (max-width: 592px) {
    .mapa, .mapacovertura{
      width: 100% !important;
      height: 500px !important;
    }
  }
  @media (max-width: 542px) {
    .mapa, .mapacovertura{
      width: 100% !important;
      height: 450px !important;
    }
  }

  @media (max-width: 492px) {
    .mapa, .mapacovertura{
      width: 100% !important;
      height: 400px !important;
    }
  }

  @media (max-width: 442px) {
    .mapa, .mapacovertura{
      width: 100% !important;
      height: 350px !important;
    }
  }

  @media (max-width: 392px) {
    .mapa, .mapacovertura{
      width: 100% !important;
      height: 300px !important;
    }
  }

  @media (max-width: 342px) {
    .mapa, .mapacovertura{
      width: 100% !important;
      height: 250px !important;
    }
  }

  @media (max-width: 292px) {
    .mapa, .mapacovertura{
      width: 100% !important;
      height: 200px !important;
    }
  }

  .ContenedorCodigoInformacion{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5px !important;
  }
  
  

  .mapa {
    border: 3px solid var(--color-rosa);
    border-radius: 20px;
    height: 600px;
    overflow: hidden;
  }

  .mapacovertura {
    border: 3px solid #2CD673;
    border-radius: 20px;
    height: 600px;
    overflow: hidden;

  }
  
   a{
    color:var(--color-rosa) !important;
  }
  .cobertura ul li{
    color:var(--color-rosa) !important;
  }
  #telefono{
    color:black !important;
  }


  .conCodigo{
    background-color: var(--color-amarilo);
    /* height: 90px !important; */
    border-radius: 5px;
    text-align: center !important;
    width: 100%;
    margin-bottom: 25px;
    padding: 0px;
    
}
#iCodigo{
  display: flex;
  justify-content: center;
  align-items: baseline;
  /* margin-left: 20% !important; */
}
#divContinuar{
  text-align: right;
}
#divInformacion{
  margin-bottom: 0px;
}
.modal-content{
  padding: 10px;
  border: 2px solid var(--color-rosa);
  border-radius: 15px;
}
.modal-body{
  border: 0;
}
.modal-header{
  border: 0;
}

.informacion{
  font-size:15px ;
  font-weight: 400 !important;
}


.selectdiv {
  position: relative;
      height: 40px;
}
.selectdiv:after {
   font: normal normal normal 17px/1 FontAwesome;
   content: '\f078' ;
    color: #d14081 ;
    right: 9px;
    top: 0px;
    height: 34px;
    padding: 15px 0px 0px 8px;
    /* border-left: 1px solid #0ebeff; */
    position: absolute;
    pointer-events: none;
}

/* IE11 hide native button (thanks Matt!) */
select::-ms-expand {
display: none;
}

.selectdiv select {
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    /* max-width: 340px; */
    height: 35px;
    float: right;
    margin: 5px 0px;
    padding: 0px 10px;
    font-size: 16px;
    line-height: 1.75;
    color: #333;
    border: 1px solid #c4c4c4;
    -ms-word-break: normal;
    border-radius: 5px;
    word-break: normal;
}