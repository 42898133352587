/* @font-face {
    font-family: "Itaca";
    src: url("../../../assets/fonts/itaca/Itaca\ Wide.ttf") format("truetype");
  } */

 
  
  *,i,ul>li {
    font-family: 'itaca'  !important;
    font-weight: normal !important;
  }

   
  
  :root {
    --color-rosa: #D14081 !important;
    --color-amarilo: #ffec00 !important;
    --color-gris:#c8cacb !important;
  
  }
  .headerInicio {
    /*background: url("/assets/img/bg.png");*/
    background-size: cover;
    background-repeat: no-repeat;
    height: 30vh;
    background-position: center;
  }
  /*
  @media (max-width: 768.98px) {
    .headerInicio {
      height: 100vh;
    }
  }*/
  
  .inicioWrap {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .inicioWrap .tituloWrap {
    margin-top: 7%;
    margin-bottom: 25px;
  }
  .inicioWrap .tituloWrap .titulo {
    font-size: 72px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    text-shadow: 0px 2px 15px rgba(0, 0, 0, 0.71);
  }
  @media (max-width: 575.98px) {
    .inicioWrap .tituloWrap .titulo {
      font-size: 42px !important;
    }
  }

  .aspectRatio{
    aspect-ratio: 1.25 ;
  }

  @media (max-width: 600px) {
    .aspectRatio{
      aspect-ratio: 2 ;
    }
  }

  .inicioWrap .botonesWrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 300.98px) {
    .inicioWrap .botonesWrap {
      flex-direction: column;
    }
  }
  
  .btnAmarillo,.btnAmarilloGrande {
    padding: 16px 24px;
    margin: 8px;
    border-radius: 40px;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;
    background: #ffeb00;
    color: #000;
    border: none;
    cursor: pointer;
  }
  
  .btnAmarillo:hover,.btnAmarilloGrande:hover {
    background-color: var(--color-amarilo);
    color: #fff;
    border: none;
    transition: 0.3s ease;
  }
  .btnAmarilloChico {
    background-color: var(--color-amarilo);
    border-radius: 10px;
    border: 1px solid var(--color-amarilo);
    color: black;
    font-weight: 400;
    width: 115px;
  }
  .btnAmarilloChico:hover {
    background-color: white;
    border: 1px solid var(--color-amarilo);
    color: black;
  }
  
  .btnGrisChico,.btnGrisGrande {
    background-color: var(--color-gris);
    border-radius: 10px;
    border: 1px solid var(--color-gris);
    color: white;
    font-weight: 400;
  }
  .btnGrisChico:hover,.btnGrisGrande:hover {
    background-color:white;
    border: 1px solid var(--color-gris);
    color: black;
  }
  
  
  @media (max-width: 575.98px) {
    .btnAmarillo {
      font-size: 18px;
    }
  }
  
  .btnBlanco {
    padding: 16px 24px;
    margin: 8px;
    border-radius: 40px;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;
    background: #fff;
    color: #000;
    border: none;
    cursor: pointer;
  }
  .btnBlanco:hover {
    background-color: #034c3c;
    color: #fff;
    border: none;
    transition: 0.3s ease;
  }
  @media (max-width: 575.98px) {
    .btnBlanco {
      font-size: 18px;
    }
  }
  
  .btnFooter {
    border: 2px solid #271f30;
    padding: 12px 18px;
    margin: 5px;
    border-radius: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #271f30;
    cursor: pointer;
  }
  .btnFooter:hover {
    background-color: #271f30;
    color: #fff;
  }
  @media (max-width: 991.98px) {
    .btnFooter:hover {
      background-color: #fff;
      color: #271f30;
    }
  }
  @media (max-width: 991.98px) {
    .btnFooter {
      border: 2px solid #fff;
      color: #fff;
    }
  }
  
  .footerWrap {
    background-image: url("../../../assets/Imagenes/Sets/curva.png");
    background-position: left;
    background-size: 60vw auto;
    background-repeat: no-repeat;
    background-color: #ffeb00;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6% 12%;
    height: 300px;
    margin-top: 180px;
  }
  @media (max-width: 991.98px) {
    .footerWrap {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      padding: 10% 7%;
      background-image: none;
      background-color: #d13f81;
    }
  }
  .footerWrap .footerIzq {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .footerWrap .footerIzq .logo {
    margin-bottom: 30px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 991.98px) {
    .footerWrap .footerIzq .logo {
      height: 80px;
    }
  }
  .footerWrap .footerIzq .logo img {
    height: 100%;
    width: auto;
  }
  .footerWrap .footerIzq .texto {
    font-weight: 400;
    font-size: 20px;
    color: #fff;
  }
  .footerWrap .footerDer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50%;
  }
  @media (max-width: 991.98px) {
    .footerWrap .footerDer {
      margin-top: 20px;
      align-items: flex-start;
      width: 100%;
    }
  }
  .footerWrap .footerDer .texto2 {
    font-weight: 400;
    font-size: 20px;
    color: #271f30;
    text-align: right;
    margin-bottom: 15px;
  }
  @media (max-width: 991.98px) {
    .footerWrap .footerDer .texto2 {
      text-align: left;
      color: #fff;
      font-size: 18px;
    }
  }
  .footerWrap .footerDer .texto2 span {
    color: #d13f81;
  }
  @media (max-width: 991.98px) {
    .footerWrap .footerDer .texto2 span {
      color: #ffeb00;
    }
  }
  .footerWrap .footerDer .contactoWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
  }
  @media (max-width: 991.98px) {
    .footerWrap .footerDer .contactoWrap {
      align-items: flex-start;
      justify-content: start;
    }
  }
  .footerWrap .footerDer .legal {
    margin-top: 22px;
  }
  .footerWrap .footerDer .legal a.legalLink {
    color: #271f30;
    font-weight: 400;
    font-size: 16px;
  }
  .footerWrap .footerDer .legal a.legalLink:hover {
    color: #d13f81;
  }
  
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #d13f81;
    color: #fff;
    padding-left: 90px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    /*position: fixed;*/
    top: 0;
    width: 100%;
  }
  .navbar .logo {
    margin-left: 20px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 991.98px) {
    .navbar .logo {
      padding: 5px;
      height: 55px;
    }
  }
  .navbar .logo img {
    width: auto;
    height: 100%;
  }
  .navbar input[type="checkbox"] {
    display: none;
  }
  @media (max-width: 991.98px) {
    .navbar input[type="checkbox"]:checked ~ .menu {
      display: block;
    }
  }
  .navbar .hamburger {
    display: none;
    font-size: 24px;
    user-select: none;
  }
  @media (max-width: 991.98px) {
    .navbar .hamburger {
      display: block;
      text-align: right;
      font-size: 32px;
      margin-bottom: 5px;
    }
  }
  .navbar .navLinks-list {
    background-color: #ffeb00;
    padding: 20px;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    box-shadow: -6px 0px 12px rgba(0, 0, 0, 0.39);
  }
  @media (max-width: 991.98px) {
    .navbar .navLinks-list {
      background-color: #d13f81;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 10px 20px;
      box-shadow: none;
    }
  }
  .navbar .navLinks-list .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  @media (max-width: 991.98px) {
    .navbar .navLinks-list .menu {
      display: none;
      position: absolute;
      background-color: #ffeb00;
      right: 0;
      left: 0;
      text-align: right;
      padding: 12px 0;
    }
  }
  .navbar .navLinks-list .menu .navItem {
    list-style: none;
    padding: 8px 16px;
    color: #034c3c;
  }
  @media (max-width: 991.98px) {
    .navbar .navLinks-list .menu .navItem {
      margin-top: 10px;
    }
    .navbar .navLinks-list .menu .navItem:hover {
      display: inline-block;
      transition: 0.3s ease;
    }
  }
  .navbar .navLinks-list .menu .navItem:hover {
    color: #d13f81;
    transition: 0.1s ease;
  }
  .navbar .navLinks-list .menu .navItem .navLink {
    color: inherit;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
  }
  @media (max-width: 991.98px) {
    .navbar .navLinks-list .menu .navItem .navLink {
      text-align: right;
    }
  }
  .navbar .navLinks-list .menu .navItem2 {
    list-style: none;
    padding: 8px 0 16px 8px;
  }
  @media (max-width: 991.98px) {
    .navbar .navLinks-list .menu .navItem2 {
      padding: 8px 16px;
    }
  }
  .navbar .navLinks-list .menu .infoWrap {
    margin: 8px 0;
  }
  @media (max-width: 991.98px) {
    .navbar .navLinks-list .menu .infoWrap {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
  .navbar .navLinks-list .menu .infoWrap .infoContact {
    font-size: 16px;
    font-weight: 400;
    color: #d13f81;
  }
  @media (max-width: 991.98px) {
    .navbar .navLinks-list .menu .infoWrap .infoContact {
      font-size: 16px;
    }
  }
  .navbar .navLinks-list .menu .infoWrap .infoContact:hover {
    color: #b1366e;
    text-decoration: underline;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  a {
    text-decoration: none;
  }
  
  .btnRosa,.btnRosaGrande {
    background-color: #b1366e;
    color: white;
    background-color: #b1366e !important;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
  }
  .btnRosa:hover,.btnRosaGrande:hover {
    background-color: white !important;
    color: #b1366e;
    border-radius: 5px;
    border-color: #b1366e;
    transform: scale(1.1);
  }
  
  .titulo {
    color: #D14081 !important;
    font-weight: 700;
  }
  .subtitulo {
    color: #D14081 !important;
    font-weight: 700;
    font-size: 25px;
    
  }
  input[type="text"],input[type="number"],
  select,textarea {
    border-radius: 5px !important;
    /* border: 1px solid var(--color-amarilo); */
  }
 .set input[type="text"]:focus, input[type="number"]:focus,
  select:focus,textarea:focus {
    background-color: white;
    color: black;
    border: 1px solid white !important;
    outline: 2px solid !important;
    outline-color: var(--color-amarilo) !important;
    box-shadow: 0 0 0 0.1rem var(--color-amarilo) !important;
  }


  .inputDatos input{
    /* border:  !important; */
  }
.inputDatos input[type="text"]:focus, .inputDatos input[type="number"]:focus, .inputDatos input[type="date"]:focus,
  .inputDatos select:focus, .inputDatos textarea:focus {
    background-color: white;
    color: black;
    border: 1px solid white !important;
    outline: 1px solid !important;
    outline-color: var(--color-rosa) !important;
    box-shadow: 0 0 0 0.1rem var(--color-rosa) !important;
  }
  .btnGrisGrande {
   
    width: 100%;
  }
  
  .btnRosaGrande,.btnAmarilloGrande {
    width: 100% !important;
  
  }
  
  .navbar{
    padding-top:0px !important;
    padding-bottom:0px !important;
  
  }
  dl, ol, ul {
    margin-bottom: 0px !important;
  }
  
  
  .btnRosaChico {
    background-color: var(--color-rosa);
    border-radius: 10px;
    border: 1px solid var(--color-rosa);
    color: white;
    font-weight: 400;
    width: 115px;
  }
  .btnRosaChico:hover {
    background-color: white;
    border: 1px solid var(--color-rosa);
    color: black;
  }
  




  .setfunerario button:disabled {
    background-color:var(--color-rosa) !important; /* Color de fondo gris cuando está deshabilitado */
    color: #666666; /* Color del texto gris cuando está deshabilitado */
  }




.c_rosa{
  color: var(--color-rosa) !important;
  font-size: 12px;
}
.c_negro{
  color:#777777 !important;

}



























  /*PASOS*/
  
  .divPasos {
      width: 100%;
  }
  .divPasos-step {
      display: none;
  }
  
  .divPasos-step.active {
      display: block;
      transform-origin: top;
      animation: animate .5s;
  }
  .progressoo-bar {
      position: relative;
      display: flex;
      justify-content: space-between;
      counter-reset: step;
      margin-bottom: 30px;
  }
  
  .progressoo-bar::before,
  .progressoo {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      z-index: -1;
     border: 3px dashed  var(--color-amarilo); /* ancho del borde, estilo y color */
      width: 100%; 


  }
  
  .progressoo {
      background-color: var(--color-rosa);
      width: 0;
      transition: .5s;
  }
  
  .progressoo-step {
    width: 35px;
    height: 35px;
    background-color: var(--color-amarillo);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../assets/Imagenes/Sets/steps/pendiente.png');
    background-size: cover;
    background-position: center;
}

  

  .progressoo-step::after {
      content: attr(data-title);
      position: absolute;
      top: calc(100% + 0.20rem);
      font-size: 0.85rem;
      color: black !important;
      text-align: center;
  }
  
.progressoo-step.active {
    background-color: var(--color-rosa);
    background-image: url('../../../assets/Imagenes/Sets/steps/completado.png');
    color: white;
    background-size: cover;
    background-position: center;
}
  
    /*PASOS*/


    .text{
      font-size: 24px !important;
      
    }
    .titulo{
      font-size: 42px !important;

    }

    .ImgExtras{
      cursor: pointer;
      width: 100%;
      aspect-ratio: 4 / 3;
      border-radius: 20px;
  }

  .conmemoracion{
    margin-left: 10px !important;
  }


  @media (max-width: 576px) {
    .ImgExtras{
      aspect-ratio: 16/9;
  }
  }
  