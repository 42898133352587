

@media (max-width: 500px) {
    .MyArrowLeft{
        top: calc(50% - 15px) !important;
        height: 30px !important;
        width: 30px !important;
    }
    
    .MyArrowRight{
        top: calc(50% - 15px) !important;
        height: 30px !important ;
        width: 30px !important;
    }

    .ArrowLeft{
        width:16px !important
    }

    .ArrowRight{
        width:10px !important
    }
}