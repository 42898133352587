:root {
  --color-rosa: #D14081 !important;
  --color-amarilo: #ffec00 !important;
  --color-gris:#c8cacb !important;

}
.g {
  width: 200px;
}
.card {
  border: 0;
}
.seleccionado,.seleccionado_r{
 border: 2px solid var(--color-amarilo);
}
.btnNoSeleccionado {
  background-color: white;
  border-radius: 10px;
  border: 1px solid white;
  color: black;
  font-weight: 400;
  width: 200px;
}
.btnNoSeleccionado:hover {
  background-color: white;
  border: 1px solid white;
  color: black;
}
swiper-container {
  width: 100%;
  height: 100%;
}

swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

swiper-container {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper {
  height: 80%;
  width: 100%;
}
/* .swiper-wrapper {
  
  transition-timing-function: ease-out;
  margin: 0 auto;
  display: flex !important;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
  box-sizing: content-box;
} */


.swiper-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
  display: flex !important;
  justify-content: space-around;
}


.mySwiper2 {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper2 swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper2 .swiper-slide-thumb-active {
  opacity: 1;
}

swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
swiper-slide {
  border-radius: 6px;
  padding: 7px;
}
.modal-content {
  border: 3px solid var(--color-rosa);
  border-radius: 25px;
  padding: 25px;
}

.amarillo{
  background-color: var(--color-amarilo);

}
#divVelacion,#divTotal,#divTotal_extras{
  background-color: var(--color-amarilo);
  height: auto;
  width: 100%;
  padding: 10px 20px 5px 20px;
  border-radius: 10px;
  margin-bottom: 0px;
}
#divTotal,#divTotal_extras{
  background-color: var(--color-rosa);
  color: white;

}
#divVelacion p,#divTotal p,#divTotal_extras p{
  font-size: 16px !important;
  font-weight: bold !important;
}
#divTotal,#divTotal_extras{
  margin-top: 5px;
  padding: 5px;
  text-align: center;
  height: auto;
}
#divTotal p,#divTotal_extras p{
  font-size: 19px !important;
  font-weight: 700 !important;
}

#divCarrito,#divCarrito_extras{
    border: 2px solid var(--color-amarilo);
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
}
#divCarrito h6,#divVelacion h6,#divCarrito_extras h6{
  font-size: 17px;
  font-weight: 800 !important;
  color:#4e4e4e;
}
#divBotonesAgrega{
 margin-top: 130px;
}
.seleccionado_r{
  border-radius: 30px;
}
.gris{
  border: 1px solid var(--color-gris) !important;
  background-color: var(--color-gris) !important;
  color: black !important;
}
.rosa{
  color: var(--color-rosa) !important;
}

.elimina{
  border: 0;
  color: var(--color-rosa) !important;
}

.elimina:hover{
  color: var(--color-gris) !important;
}

.c_negro{
  font-size: 12px;
}

.selectimg {
  border-radius: 20px;
  padding: 0%;
  margin: 0%;
}

.selectimg :hover{
  border-radius:20px;
  outline: solid #d14081 3px;
}


.ListSeviciosIncluidos {
  list-style-type: none; /* Quita los marcadores por defecto */
  padding: 0;
  margin: 0;
  color:#777777 ;
  font-size: 12px;
  /* padding-left: 20px; Ajusta el espacio izquierdo para la viñeta */
}

.MiniaturasProductos{
  width: 82px !important;
  height: 60px !important;
}

.MiniaturasProducto{
  width: 100% !important;
  height: 190px !important;
}



