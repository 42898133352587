:root {
    --color-rosa: #b0427e !important;
    --color-amarilo: #ffec00 !important;
    --color-gris: #c8cacb !important;
  }
  #datos p {
    font-size: 10px;
  }
  label {
    color: var(--color-rosa);
  }
  .p {
    color: var(--color-rosa);
  }
  /* input[type="checkbox"] {
    width: 20px !important;
    height: 20px !important;
  } */
  #textRight {
    display: flex;
    align-items: center;
    color: black;
  }
  #inAceptoInformacion_finado,
  #inEstoyAcuerdo_finado,
  #inCrearConmemoracion_finado {
    margin-right: 10px !important;
  }
  .linkA {
    color: var(--color-rosa) !important;
    text-decoration: underline;
  }
  .h6Condiciones {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #909090;
  }
  
  .accordion-button:focus {
    box-shadow: 0 0 0 0.1rem var(--color-rosa) !important;
  }
  
  .accordion-button:not(.collapsed)::after {
    background-image: none !important;
    /* background-image: #b0427e; */
  }
  .seleccion{
      width: 50%;
      border-radius: 10px;
      height: 43px;
      padding: 0px;
      font-size: 11px;
    }
    
  
  
    
  
  
  
  .editar{
    width: 15%;height: 25px; padding: 6px;border-radius: 5px;
  }
  
  .datossubtitulo{
    font-size: 24px;
    font-weight: 700 !important;
    color: #D14081 !important;
  }

  .datoscomentarios{
    font-size: 16px;
    font-weight: 400 !important;
    color: #3A3A3A !important;
  }

  .custom-checkbox:checked {
    background-color: pink !important; /* Cambia el color de fondo cuando está seleccionado */
  }


input:focus, textarea:focus, select:focus {
    outline: 2px solid #D14081; /* Ejemplo de un contorno azul claro */
    outline-offset: 2px; /* Desplazamiento del contorno para separarlo del borde del input */
}
