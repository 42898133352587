body {
    line-height: 1.4;
    font-family: ITACA;
    -webkit-font-smoothing: antialiased;
}

/* p {
    font-family: "Source Sans Pro", sans-serif;
    color: #848484;
    font-size: 16px;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: ITACA;
    font-weight: 600;
}


.ancho_logo {
    height: 65px;
}

h1 {
    color: #d14081;
    font-family: itaca;
}

h2 {
    color: #d14081;
    font-family: itaca;
}

.style_txt {
    font-family: itaca;
    color: black;
    font-weight: 400;
    text-align: justify;
}

.estilo_rosa {
    color: #d14081;
    font-weight: 600 !important;
}

.quest {
    float: right;
    color: #d14081;
}

.textofolio {
    text-align: center;
    color: black;
    font-size: 14px;
}

.fondo_seg {
    background: linear-gradient(to bottom, #FFFCEC 75%, #ffffff 0%);
}

.auto-container {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
}

.small-container {
    max-width: 680px;
    margin: 0 auto;
}

@media (min-width: 992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 1145px;
    }

    .degradado_header {
        background: linear-gradient(to right, #D14081 24%, #FFEC00 0%);
    }

}


.service-section .items-container {
    margin-top: 30px;
}

.service-section .items-container .slick-prev:before,
.service-section .items-container .slick-next:before {
    font-size: 25px;
    color: #48bdc5;
}

.service-section .items-container .item {
    margin: 10px 15px;
    outline: 0;
}

@media screen and (max-width: 525px) {
    .service-section .items-container .item {
        margin: 10px 5px;
    }
}


.section-title h3 {
    font-size: 40px;
    line-height: 50px;
    color: #222222;
    font-weight: 700;
    font-family: "Source Sans Pro", sans-serif;
}

.section-title span {
    color: #222222;
    font-weight: 400;
}

.section-title p {
    font-size: 15px;
    line-height: 25px;
    font-weight: 400;
    color: #777777;
    font-family: "Roboto", sans-serif;
}



.style_txt {
    font-family: itaca;
    color: black;
    font-weight: 400;
    text-align: justify;
}



.cuadro_seg {
    border-radius: 15px;
    background-color: #FFC200;
    padding: 15px;
    font-family: itaca;
}



.contact-area .form-group {
    position: relative;
    margin-bottom: 20px;
}


.contact-area .form-group select {
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    height: 50px;
    width: 100%;
    padding: 10px 20px;
    border: 1px solid #ececec;
    /* background: #f4f4f4 url(../images/icons/icon-select.png) right center no-repeat; */
    cursor: pointer;
}


.contact-area .form-group option {
    font-weight: normal;
    display: block;
    white-space: pre;
    height: 50px;
    padding: 20px;
}

.contact-area .form-group option:hover {
    background: #7b64cb;
}


.contact-area .form-group i {
    position: absolute;
    top: 16px;
    right: 20px;
    font-size: 15px;
}


.blog-section.style-five .form-group {
    margin-bottom: 20px;
}


.blog-section.style-five .form-group input {
    height: 50px;
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
    border-radius: 0px;
    box-shadow: none;
    padding: 10px 20px;
    display: inline-block;
    border: 1px solid #ececec;
}


.blog-section.style-five .form-group textarea {
    height: 160px;
    border-radius: 0px;
    box-shadow: none;
    padding: 10px 20px;
    resize: none;
    border: 1px solid #ececec;
}


.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #80808075;
    opacity: 1;
    /* Firefox */
}

.form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #80808075;
}

.form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #80808075;
}

.form-control {
    /*  border-radius: 5px !important;
border: none !important;
padding:0px 10px !important;*/
}


.form-control {
    /*  box-shadow: none;
      border-radius: 0;
      padding: 15px 20px;
      font-size: 14px;*/
}

.form-control:focus {
    box-shadow: none;
    border: 1px solid #48bdc5;
}

.form-control.main {
    padding: 20px;
}

.input_seg {
    color: #343434;
    font-weight: 500;
}



.form-group {
    margin-bottom: 1rem;
}

.labels {
    display: inline-block;
    margin-bottom: 0.5rem;
    font-size: 16px;
}

.Obligatorio {
    float: right;
    color: #d14081;
    font-size: 15px;
}



.btn_consultar {
    background-color: #d14081;
    color: white;
    width: 200px;
    margin: auto;
    font-weight: 500;
    border-radius: 6px;
    border: 1px solid #d14081;
}

.btn_consultar:hover {
    background-color: transparent;
    border: #d14081 1px solid;
    color: #d14081;
}

.btn_consultar:focus {
    color: white;
    background-color: #932C59;
    box-shadow: 0 0 0 1px rgb(255 39 136);
}

.btn_consultar:active {
    color: white !important;
    background-color: #932C5A !important;
    border-color: #932c5a !important;
}

.btn_consultar:not(:disabled):not(.disabled).active:focus,
.btn_consultar:not(:disabled):not(.disabled):active:focus,
.show>.btn_consultar.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(247 190 216) !important;
}



.txtxs{
    /* font-size: 33px; */
    color:#d14081 ;
    font-family: itaca;
  }

  .breadcrum{
    float: right;position: absolute;bottom: 0;right: 0; font-size:12px !important ; margin-right: 22px;
  }
  .breadcrum_active{
    color: #d14081;font-weight: 500;
    font-size:12px
  }


  .color_ros{
    color: #d14081;
    font-weight:700 !important;
}

.parrafo_der{
    font-size: 15px;float: right;margin-right: 5px;text-align: right;
  }


  .pasoscaja{
    height: 90px;
    margin-top: 40px !IMPORTANT;
  }

  .linea_punteada{
    height: 5px;margin: auto;margin-left: 110px;width: 547px;background: radial-gradient(circle closest-side, #ffec00 90%, #0000) 0/14px 100%;
  }

  .linea_punteada{
    margin-left: 5px;
    width: 445px;
  }

  .linea_punteada {
    height: 7px;
    margin: auto;
    margin-left: 15px;
    width: 345px;
    background: radial-gradient(circle closest-side, #ffec00 90%, #0000) 0/14px 100%;
  }

  .linea_punteada{
    margin-left: 5px;
    width: 445px;
  }

  .paso1{
    height: 8px;position: absolute;margin: auto;width: 700px; left: 135px;top: -30px;
  }
  .paso2{
    height: 8px;position: absolute;margin: auto;width: 700px; left: 235px;top: -30px;
  }
  .paso3{
    height: 8px;position: absolute;margin: auto;width: 700px; left: 335px;top: -30px;
  }
  .paso4{
    height: 8px;position: absolute;margin: auto;width: 700px; left: 435px;top: -30px;
  }
  .paso5{
    height: 8px;position: absolute;margin: auto;width: 700px; left: 535px;top: -30px;
  }
  .paso6{
    height: 8px;position: absolute;margin: auto;width: 700px; left: 635px;top: -30px;
  }


  .paso1{
    left: 20px;
    top: -25px;
  }
  .paso2{
    left: 95px;
    top: -25px;
  }
  .paso3{
    left: 175px;
    top: -25px;
  }
  .paso4{
    left: 260px;
    top: -25px;
  }
  .paso5{
    left: 345px;
    top: -25px;
  }
  .paso6{
    left: 420px;
    top: -25px;
  }

  .paso3{
    display: none;
}
.paso4{
    display: none;
}
.paso1{
    display: none;
}
.paso2{
    display: none;
}

.paso1{
    left: 20px;
    top: -25px;
  }
  .paso2{
    left: 95px;
    top: -25px;
  }
  .paso3{
    left: 175px;
    top: -25px;
  }
  .paso4{
    left: 260px;
    top: -25px;
  }
  .paso5{
    left: 345px;
    top: -25px;
  }
  .paso6{
    left: 420px;
    top: -25px;
  }

  .circulo{
    margin-left: 5px; margin-bottom: 4px;
  }
  .circulo {
    margin-left: 12px;
    margin-bottom: 4px;
    height: 50px;
}

.circulo{
    width: 85px;
}
.circulo {
    margin-left: 12px;
    margin-bottom: 4px;
    height: 50px;
}

.txt_seg{
    line-height: 15px;
  text-align: center;
  font-family: itaca;
  font-size: 12px;
  color: #d14081;
  position: absolute;
  font-weight: 500;
  }

  .txt_seg2{
    line-height: 15px;
  text-align: center;
  font-family: itaca;
  font-size: 12px;
  color: #484848;
  position: absolute;
  }
  

  .txt_seg{
    font-size: 18px;
    line-height: 20px;
  }
  .txt_seg2{
    font-size: 18px;
    line-height: 20px;
  }


.anchotabla {
    /* background-image: url(../images/tabla_ejem2.png); */
    height: 280px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .boton_amarillo {
    padding: 5px 22px;
    background-color: #FFEC00;
    color: #484848;
    border: none;
    font-weight: 500;
    border-radius: 5px;
  }
  
  .boton_amarillo:hover {
    border: 1px solid #FFEC00;
    background-color: white;
    color: #6e6e6e;
  }
  
  .boton_amarillo:not(:disabled):not(.disabled).active,
  .boton_amarillo:not(:disabled):not(.disabled):active,
  .show>.boton_amarillo.dropdown-toggle {
    color: #fff;
    background-color: #BFB31C;
    border-color: #938a1e;
  }
  
  /* .boton_amarillo {
    width: 100%;
  } */


  .lateral {
    background-color: #FFFCEC;
    height: auto;
  }


  .paso1 {
    height: 8px;
    position: absolute;
    margin: auto;
    width: 700px;
    left: 135px;
    top: -30px;
  }
  
  .paso2 {
    height: 8px;
    position: absolute;
    margin: auto;
    width: 700px;
    left: 235px;
    top: -30px;
  }
  
  .paso3 {
    height: 8px;
    position: absolute;
    margin: auto;
    width: 700px;
    left: 335px;
    top: -30px;
  }
  
  .paso4 {
    height: 8px;
    position: absolute;
    margin: auto;
    width: 700px;
    left: 435px;
    top: -30px;
  }
  
  .paso5 {
    height: 8px;
    position: absolute;
    margin: auto;
    width: 700px;
    left: 535px;
    top: -30px;
  }
  
  .paso6 {
    height: 8px;
    position: absolute;
    margin: auto;
    width: 700px;
    left: 635px;
    top: -30px;
  }
  
  .circulo {
    margin-left: 5px;
    margin-bottom: 4px;
  }
  

  .breadcrum {
    float: right;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 11px !important;
    margin-right: 22px;
  }
  
  .breadcrum_active {
    color: #d14081;
    font-weight: 500;
  }
  
  .pt-0 {
    padding-top: 0 !important;
  }

  .fotocompartir {
    border-radius: 15px;
    border: 3px solid #d04080;
    width: 250px;
    height: 250px;
    background-size: contain;
  }
  

  .textofolio {
    text-align: center;
    color: black;
    font-size: 14px;
  }
  

  
.sharefb {
  background-image: url(../../../assets/Imagenes/Seguimiento/icons/fb.png);
  height: 50px;
  background-repeat: no-repeat;
  margin: auto;
  background-size: contain;
  background-position: center;
  transition: 0.7s;
  z-index: 0;
  cursor: pointer;
}

.sharetw {
  background-image: url(../../../assets/Imagenes/Seguimiento/icons/tw.png);
  height: 50px;
  background-repeat: no-repeat;
  margin: auto;
  background-size: contain;
  background-position: center;
  transition: 0.7s;
  z-index: 0;
  cursor: pointer;
}

.shareinsta {
  background-image: url(../../../assets/Imagenes/Seguimiento/icons/insta.png);
  height: 50px;
  background-repeat: no-repeat;
  margin: auto;
  background-size: contain;
  background-position: center;
  transition: 0.7s;
  z-index: 0;
  cursor: pointer;
}

.sharewhatsapp {
  background-image: url(../../../assets/Imagenes/Seguimiento/icons/whatsapp.png);
  height: 50px;
  background-repeat: no-repeat;
  margin: auto;
  background-size: contain;
  background-position: center;
  transition: 0.7s;
  z-index: 0;
  cursor: pointer;
}

.sharecopiar {
  background-image: url(../../../assets/Imagenes/Seguimiento/icons/copiar.png);
  height: 50px;
  background-repeat: no-repeat;
  margin: auto;
  background-size: contain;
  background-position: center;
  transition: 0.7s;
  z-index: 0;
  cursor: pointer;
}