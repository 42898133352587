.carousel{
  margin-top: 10px !important;
}
 
 .carousel .thumb:hover {
  border: none;
  outline: none;
  /* display: flex;
  justify-content: center; */
}

.carousel .thumb.selected{
    border: none;
    outline: none;

} 
.carousel .thumbs-wrapper {
  margin: 5px 0px 0px 0px;
  overflow: hidden;
}

.carousel-root {
  /* display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'space-between', */
display: flex;
flex-wrap: wrap;
align-content: space-between;
}


.carousel .carousel .thumbs-wrapper {
  margin: 0px; 
}

.carousel .thumbs {
  transition: all .15s ease-in;
  transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  opacity: .5;
}
.thumbs .animated ol, ul {
  padding-left: 0px !important;
}

/* 
.thumb.selected:focus{
  border: none  !important;
}

.thumb :{
  border: none  !important;
} */

.carousel .thumb {
  transition: none;
  display: inline-block;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 2px  2px  0px  2px;
}

.thumb{
  width: 30% !important;
  display: flex;
  justify-content: space-between;
  margin: 0% !important;
  padding: 0% !important;
}