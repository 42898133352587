.titulopago {
    background-color: #e9e9e9;
    font-size: 24px;
    line-height: 24px;
}

.Marcesina{
    height: 80px;
    background-color: #e9e9e9;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
}

.cards{
width: 100%;
}

.InputGenral{
    width: 100%;
    height: 40px;
}

