

  .carritoFixed {
    width:100% ;
  }

  @media (max-width: 1120px) {
    .carritoFixed {
      width: calc(99% - 0px);
    }
  }
  .carrito {
    width:100%;
  }                   