:root {
    --color-rosa: #D14081 !important;
    --color-amarilo: #ffec00 !important;
    --color-gris: #c8cacb !important;
    --color-verde:#374957 !important;
  }
  
  .card {
    height: 100%;
    border-radius: 15px;
    border-color: white;
    background-color: #F9F9F9 !important;

  }
  .card-header {
    background-color: var(--color-amarilo);
    padding: 0px !important;
    color: black;
    text-align: center;
    border: 0px;
  }
  .card-body {
    text-align: center;
    padding: 0px !important;
    /* background-color: #F9F9F9; */

  }
  .card-footer {
    text-align: center;
    border: 0px;
    /* background-color: #F9F9F9 !important; */
  }
  .card,
  .card-footer {
    position: relative;
    overflow: hidden;
    background-color: transparent;
    transition: background-color 0.5s ease-in-out;
  }
  
  .set .card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: linear-gradient(
      to bottom,
      var(--color-amarilo) 0%,
      var(--color-amarilo) 100%
    ) !important;
    z-index: -1;
    transition: height 0.3s ease-in-out;
  }
  
  .card:hover::before {
    height: 100%;
  }
  
  .card:hover {
    background-color: transparent !important;
  }
  
  .btn-rosa {
    border-color: var(--color-rosa);
    background-color: var(--color-rosa);
    color: white;
  }
  .btn-rosa:hover {
    border: 1px solid white;
    background-color: white;
    color: var(--color-rosa);
  }
  
  .set{
    font-size: 18px;
    font-weight: 700 !important;
    color: black !important;
  }

  .subset{
    font-size: 25px;
    font-weight: 700 !important;
    color:black !important;
  }
  .subtitulo{
    color: var(--color-rosa) !important;
    font-size: 30px;
    font-weight: 900 !important;
  
  }
  .titulo{
    color: var(--color-rosa) !important;
    font-size: 30px;
    font-weight: 700 !important;
  }
  .titulocobertura{
    font-weight: 400 !important;
    font-size: 40px;
    color: var(--color-rosa) !important;

  }

  .beneficios {
    color: var(--color-rosa) !important;
    font-size: 25px;
    font-weight: 700 !important;
  }

  .listbeneficios
  {
    font-size: 16px;
    /* font-weight: 600 !important; */
    color:'#3A3A3A';
    margin:0px;
  }

  @media (max-width: 576px) {
    .containerset{
      width: 100% !important;
    }
  }
