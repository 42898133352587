.textobase
{
    text-align: justify;
    font-size: 14px !important;
    font-family: Itaca;
    font-weight: 400;
    margin-bottom: 25px !important;
}

.textonegritas{
    text-align: justify;
    font-size: 14px !important;
    font-family: Itaca;
    font-weight: 700 !important;
    margin-bottom: 25px !important;
}

.inline
{
    display: inline !important;
}


.textotitulo{
    text-align: center;
    font-size: 14px !important;
    font-family: Itaca;
    font-weight: 700 !important;
    margin-bottom: 50px !important;
}

.listaacuerdo{
    font-family: Itaca;
    font-size: 14px !important;

    padding-left: 3rem !important;
    margin-bottom: 25px !important;
}

.sublistaacuerdo{
    font-family: Itaca;
    font-size: 14px !important;
    padding-left: 2rem !important;
    margin-bottom: 15px !important;
}

.sublista{
    font-family: Itaca;
    font-size: 14px !important;
    padding-left: 4rem !important;
    margin-bottom: 25px !important;

}

.subclausula {
  margin-left: 5rem !important;
  font-size: 14px !important;
  margin-bottom: 25px !important;
}

.bold{
    font-weight: 700 !important;
}

.listsizeupp li::marker {
    font-size: 1.2rem; /* Aumenta el tamaño del marcador */
}

.PdL_2{
    padding-left: 3rem !important;
}

.vinculo{
    color: blue;
}

.subrayado 
{
    text-decoration: underline;

}