

@media (max-width: 600px) {
    .range-slider .range-slider__thumb {
        width: 12px !important;
        height: 12px !important;
    }
    .range-slider .range-slider__range {
        height: 7px !important;
    }
    .range-slider  {
        height: 9px !important;
    }
    
}

.range-slider .range-slider__thumb {
    width: 17px;
    height: 17px;
    background: #D14081 !important;
}

/* @media (max-width: 450px) {
    .range-slider .range-slider__thumb {
        width: 12px;
        height: 12px;
    }
    
} */

@media (max-width: 600px) {
    .time{
        width: 100px;
    }
}


.range-slider .range-slider__range {
    background: #D14081;
    height: 10px;
}

.range-slider  {
    height: 13px;
}

